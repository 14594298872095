import React, { useState } from "react";
function TextForm(props) {
  const [text, setText] = useState("Enter text here");
  const handleUpClick = () => {
    setText(text.toUpperCase());
  };
  const handleOnChange = (e) => {
    setText(e.target.value);
  };
  return (
    <>
      <div className="container">
          <h1> {props.title} </h1>
          <div className="mb-3">
            <label htmlFor="myBox" className="form-label">
              {props.name}
            </label>
            <textarea
              className="form-control"
              id="myBox"
              rows="15"
              value={text}
              onChange={handleOnChange}></textarea>
          </div>
          <button className="btn btn-primary" onClick={handleUpClick}>
            UPPERCASE
          </button>
      </div>
      <div className="container my-2">
        <h2>
            <ul>
                <li>Your realtime speed</li>
                <li></li>
            </ul>
        </h2>
      </div>
    </>
  );
}
export default TextForm;
