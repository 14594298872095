import "./App.css";
import Navbar from "./components/Navbar";
import TextForm from "./components/TextForm";
import Timer from "./components/Timer";

export default function App() {
  return (
    <div>
      <div className="container">
        <Navbar />
        <Timer />
        <TextForm title="Analyze your text here" name="Text Box" />
      </div>
    </div>
  );
}
