import { useState, useEffect } from "react";

function Timer() {
  const [secondsLeft, setSecondsLeft] = useState(600);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSecondsLeft((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const minutes = Math.floor(secondsLeft / 60);
  const seconds = secondsLeft % 60;

  return (
    <div>
      <button className="btn btn-secondary mx-2">Start the timer</button>
      {minutes}:{seconds < 10 ? "0" : ""}
      {seconds}
    </div>
  );
}
export default Timer;
